// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_rK d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_rL d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_rM d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_rN d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_rP d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_rQ d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_rR d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rS x_rS";
export var heroExceptionNormal = "t_rT x_rT";
export var heroExceptionLarge = "t_rV x_rV";
export var Title1Small = "t_rW x_rW x_sf x_sg";
export var Title1Normal = "t_rX x_rX x_sf x_sh";
export var Title1Large = "t_rY x_rY x_sf x_sj";
export var BodySmall = "t_rZ x_rZ x_sf x_sy";
export var BodyNormal = "t_r0 x_r0 x_sf x_sz";
export var BodyLarge = "t_r1 x_r1 x_sf x_sB";